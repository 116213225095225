import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SignalStatesService {

  private loggedStorage = signal({
    // storage login
    isLoggedIn: false, // garante a guarda de rotas, padrão false para bloqueio.
    onboarding: {
      builder_url: '',
      change_password_token: '',
      stage_number: 0,
      master_church: {
        is_site_active: false,
      },
      id: 0,
      resource_uri: '',
      responsible: {},
      subgroup: {
        id: 0,
        independent_website_url: '',
      },
    },
    basic_user_person: {
      authorization: '',
      basic_user: {
        email: '',
      },
      onboarding_id: '',
      token: '',
    },
    tertiarygroup: {
      id: 0,
      name: '',
      email: '',
      cnpj: '',
      corporate_name: '',
      trade_name: '',
      owner_name: '',
      owner_cpf: '',
      location: {
        address: '',
        address_number: '',
        country: { id: 1, name: 'Brasil', resource_uri: "/api/v1/country/1/", slug: "BR" },
        city: {},
        state: {},
        zip_code: '',
        international_state: '',
        international_city: '',
      },
    },
  });

  public loggedStorageSignal = this.loggedStorage.asReadonly();

  private valuesFormsSignal = signal({
    // password_setting
    email: '',
    password: '',
    confirmPassword: '',
    // basic_data
    nameChurch: '', //
    emailChurch: '', //
    address: '', //
    addressNumber: '', //
    quantityMembers: '',
    country: { id: 1, name: 'Brasil', resource_uri: "/api/v1/country/1/", slug: "BR" }, 
    state: {},
    city: {},
    cep: '', //
    postalCode: '', //
    stateIntenational: '',
    cityInternational: '',
    // cnpj_registration
    CNPJNumber: '',
    corporateReason: '',
    nameFantasy: '',
    responsibleAtQSA: '',
    CPFofThePersonResponsible: '',
    hasCnpj: true,
    willCreateACnpj: true,
    // church_website
    hasWebSite: false,
    siteLink: '',
    willYouHireAwebsite: 'hireWebsite',
    // financial_account
    bank: {
      code: 0,
      id: 0,
      is_active: true,
      is_highlighted: false,
      name: '',
      resource_uri: '',
    },
    agency: '',
    agencyDigit: '',
    account: '',
    accountDigit: '',
    responsibleName: '',
    responsibleEmail: '',
    responsiblePhone: '',
    // apple_account
    howToProceedWithAppleAccountCreation: '',
    wantToHireSpecialist: false,
    appleDeveloperNameAccount: '',
    appleDeveloperEmailAccount: '',
    dunsNumber: '',
    developerAccountName: '',
    confirmCompletedAllSteps: false,
  });
  
  public formsValuesSignal = this.valuesFormsSignal.asReadonly();
  
  public menuActiveRoute = signal([
    { id: 1, active: true, name: 'Definição de senha', route: 'password_setting' },
    { id: 2, active: true, name: 'Dados básicos', route: 'basic_data' },
    { id: 3, active: true, name: 'Cadastro do CNPJ', route: 'cnpj_registration' },
    { id: 4, active: true, name: 'Site da igreja', route: 'church_website' },
    { id: 5, active: true, name: 'Conta financeira', route: 'financial_account' },
    { id: 6, active: true, name: 'Conta Apple', route: 'apple_account' },
    { id: 7, active: true, name: 'Permissões', route: 'user_permissions' },
    { id: 8, active: true, name: 'Definição de layouts', route: 'layout_definition' },
    { id: 9, active: false, name: 'Conclusão', route: 'finish' },
    { id: 10, active: false, name: 'Erro', route: 'not-found' },
  ]);

  public updateDataSignal(item: any, value: any) {
    this.valuesFormsSignal.update((current) => {
      return { ...current, [item]: value };
    });    
  }

  public updateLoggedStorageSignal(item: any, value: any) {
    this.loggedStorage.update((current) => {
      return { ...current, [item]: value };
    });
  }

  public updateValuesFormsSignal() {
    const loggedStorage = this.loggedStorageSignal();

    const stagesSignal: any = this.pendingStagesSignal().filter(item => item['id'] === 6)[0];
    
    this.valuesFormsSignal.update((current) => {
      return {
        ...current,
        email: loggedStorage.basic_user_person.basic_user.email,
        nameChurch: loggedStorage.tertiarygroup.name,
        emailChurch: loggedStorage.tertiarygroup.email,
        quantityMembers: '',
        address: loggedStorage.tertiarygroup.location.address,
        addressNumber: loggedStorage.tertiarygroup.location.address_number,
        country: loggedStorage.tertiarygroup.location.country || {
          id: 1,
          name: 'Brasil',
          resource_uri: "/api/v1/country/1/", 
          slug: "BR", 
        },
        state: loggedStorage.tertiarygroup.location.state,
        city: loggedStorage.tertiarygroup.location.city,
        cep: loggedStorage.tertiarygroup.location.zip_code,
        postalCode: loggedStorage.tertiarygroup.location.zip_code,
        stateIntenational:
          loggedStorage.tertiarygroup.location.international_state,
        cityInternational:
          loggedStorage.tertiarygroup.location.international_city,
        // cnpj_registration
        CNPJNumber: loggedStorage.tertiarygroup.cnpj,
        corporateReason: loggedStorage.tertiarygroup.corporate_name,
        nameFantasy: loggedStorage.tertiarygroup.trade_name,
        responsibleAtQSA: loggedStorage.tertiarygroup.owner_name,
        CPFofThePersonResponsible: loggedStorage.tertiarygroup.owner_cpf,
        hasCnpj: loggedStorage.tertiarygroup.cnpj ? true : false,

        willCreateACnpj: true,
        // church_website
        hasWebSite: loggedStorage.onboarding.subgroup.independent_website_url ? true : false, 
        siteLink: loggedStorage.onboarding.subgroup.independent_website_url, 
        willYouHireAwebsite: 'hireWebsite', // ver com o BE de onde virá o campo.
        // financial_account // ver para onde será enviado esses dados (impayment / granito)???
        bank: {
          code: 0,
          id: 0,
          is_active: false,
          is_highlighted: false,
          name: '',
          resource_uri: '',
        },
        agency: '',
        agencyDigit: '',
        account: '',
        accountDigit: '',
        responsibleName: '',
        responsibleEmail: '',
        responsiblePhone: '',
        // apple_account  // ainda em desenvolvimento
        howToProceedWithAppleAccountCreation: stagesSignal.step?.step_chosen || '',
        wantToHireSpecialist: stagesSignal.step?.has_consent || false,
        appleDeveloperNameAccount: stagesSignal.step?.info_data?.apple_account.name || '',
        developerAccountName: stagesSignal.step?.info_data?.apple_account.name || '',
        appleDeveloperEmailAccount: stagesSignal.step?.info_data?.apple_account.email || '',
        dunsNumber: stagesSignal.step?.info_data?.duns_number
 || '',        
      };

    });

  }

  // update pending stages

  private pendingStages = signal([]);

  public pendingStagesSignal = this.pendingStages.asReadonly();

  public updatePendingStagesSignal(item: any) {
    this.pendingStages.set(item); 
    console.log('pendingStages', this.pendingStages());   
  }
}
