import * as i0 from '@angular/core';
import { Component, Input, NgModule, Directive } from '@angular/core';
import { CommonModule } from '@angular/common';
const _c0 = ["*"];
class MatchaCardComponent {
  constructor() {
    this.elevation = 0;
    this.blockquote = 'none';
    this.color = 'surface';
    this.blockquoteColor = null;
    this.class = '';
    this.alpha = false;
    this.loading = false;
  }
  get classes() {
    const elevation = `elevation-z-${this.elevation}`;
    let blockquoteposition = this.blockquote === 'right' || 'left' ? `matcha-card-border-${this.blockquote}` : '';
    let backgroundColor = '';
    let blockquoteColor = '';
    if (this.color === 'bg' || this.color === 'surface') {
      backgroundColor = `background-${this.color}${this.alpha ? '-alpha' : ''}`;
      blockquoteColor = this.blockquoteColor ? `border-color-${this.blockquoteColor}` : '';
    } else {
      backgroundColor = `background-${this.color}${this.alpha ? '-alpha' : ''} border-color-${this.color}`;
      this.alpha ? blockquoteposition = this.blockquote === 'right' || 'left' ? `matcha-card-border-${this.blockquote}` : '' : blockquoteposition = '';
    }
    const activeClasses = `matcha-card ${elevation} ${blockquoteposition} ${blockquoteColor} ${backgroundColor} ${this.loading ? 'loading' : ''} ${this.class}`;
    return activeClasses;
  }
  static {
    this.ɵfac = function MatchaCardComponent_Factory(t) {
      return new (t || MatchaCardComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: MatchaCardComponent,
      selectors: [["matcha-card"]],
      inputs: {
        elevation: "elevation",
        blockquote: "blockquote",
        color: "color",
        blockquoteColor: "blockquoteColor",
        class: "class",
        alpha: "alpha",
        loading: "loading"
      },
      ngContentSelectors: _c0,
      decls: 2,
      vars: 2,
      template: function MatchaCardComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "div");
          i0.ɵɵprojection(1);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.classes);
        }
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaCardComponent, [{
    type: Component,
    args: [{
      selector: 'matcha-card',
      template: "<div [class]=\"classes\">\n    <ng-content></ng-content>\n</div>\n"
    }]
  }], null, {
    elevation: [{
      type: Input
    }],
    blockquote: [{
      type: Input
    }],
    color: [{
      type: Input
    }],
    blockquoteColor: [{
      type: Input
    }],
    class: [{
      type: Input
    }],
    alpha: [{
      type: Input
    }],
    loading: [{
      type: Input
    }]
  });
})();
class MatchaHeadlineComponent {
  constructor() {
    this.bulletColor = 'accent';
    this.loading = false;
  }
  get classes() {
    const bullet = this.bulletColor ? `background-${this.bulletColor} min-h-24 h-24 max-h-24 max-w-8 w-8 min-w-8 d-inline-block position-relative mr-8 border-radius-8` : '';
    const activeClasses = `${bullet} ${this.loading ? 'loading' : ''}`;
    return activeClasses;
  }
  static {
    this.ɵfac = function MatchaHeadlineComponent_Factory(t) {
      return new (t || MatchaHeadlineComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: MatchaHeadlineComponent,
      selectors: [["matcha-headline"]],
      inputs: {
        bulletColor: "bulletColor",
        loading: "loading"
      },
      ngContentSelectors: _c0,
      decls: 3,
      vars: 2,
      consts: [[1, "lh-16", "d-flex", "d-flex-align-center", "m-0", "h4"]],
      template: function MatchaHeadlineComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "h4", 0);
          i0.ɵɵelement(1, "span");
          i0.ɵɵprojection(2);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵadvance();
          i0.ɵɵclassMap(ctx.classes);
        }
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaHeadlineComponent, [{
    type: Component,
    args: [{
      selector: 'matcha-headline',
      template: "<h4 class=\"lh-16 d-flex d-flex-align-center m-0 h4\"><span [class]=\"classes\"></span><ng-content></ng-content></h4>\n"
    }]
  }], null, {
    bulletColor: [{
      type: Input
    }],
    loading: [{
      type: Input
    }]
  });
})();
class MatchaSubheadComponent {
  constructor() {
    this.bulletColor = 'accent';
    this.loading = false;
  }
  get classes() {
    const bullet = this.bulletColor ? `background-${this.bulletColor} min-h-20 h-20 max-h-20 max-w-8 w-8 min-w-8 d-inline-block position-relative mr-8 border-radius-8` : '';
    const activeClasses = `${bullet} ${this.loading ? 'loading' : ''}`;
    return activeClasses;
  }
  static {
    this.ɵfac = function MatchaSubheadComponent_Factory(t) {
      return new (t || MatchaSubheadComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: MatchaSubheadComponent,
      selectors: [["matcha-subhead"]],
      inputs: {
        bulletColor: "bulletColor",
        loading: "loading"
      },
      ngContentSelectors: _c0,
      decls: 3,
      vars: 2,
      consts: [[1, "lh-16", "d-flex", "d-flex-align-center", "m-0", "h5"]],
      template: function MatchaSubheadComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "h5", 0);
          i0.ɵɵelement(1, "span");
          i0.ɵɵprojection(2);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵadvance();
          i0.ɵɵclassMap(ctx.classes);
        }
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaSubheadComponent, [{
    type: Component,
    args: [{
      selector: 'matcha-subhead',
      template: "<h5 class=\"lh-16 d-flex d-flex-align-center m-0 h5\"><span [class]=\"classes\"></span><ng-content></ng-content></h5>\n"
    }]
  }], null, {
    bulletColor: [{
      type: Input
    }],
    loading: [{
      type: Input
    }]
  });
})();
class MatchaTitleComponent {
  constructor() {
    this.bulletColor = 'accent';
    this.loading = false;
  }
  get classes() {
    const bullet = this.bulletColor ? `background-${this.bulletColor} min-h-16 h-16 max-h-16 max-w-8 w-8 min-w-8 d-inline-block position-relative mr-8 border-radius-8` : '';
    const activeClasses = `${bullet} ${this.loading ? 'loading' : ''}`;
    return activeClasses;
  }
  static {
    this.ɵfac = function MatchaTitleComponent_Factory(t) {
      return new (t || MatchaTitleComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: MatchaTitleComponent,
      selectors: [["matcha-title"]],
      inputs: {
        bulletColor: "bulletColor",
        loading: "loading"
      },
      ngContentSelectors: _c0,
      decls: 3,
      vars: 2,
      consts: [[1, "lh-24", "d-flex", "d-flex-align-center", "m-0", "h8"]],
      template: function MatchaTitleComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "h6", 0);
          i0.ɵɵelement(1, "span");
          i0.ɵɵprojection(2);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵadvance();
          i0.ɵɵclassMap(ctx.classes);
        }
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaTitleComponent, [{
    type: Component,
    args: [{
      selector: 'matcha-title',
      template: "<h6 class=\"lh-24 d-flex d-flex-align-center m-0 h8\"><span [class]=\"classes\"></span><ng-content></ng-content></h6>\n"
    }]
  }], null, {
    bulletColor: [{
      type: Input
    }],
    loading: [{
      type: Input
    }]
  });
})();
class MatchaIconComponent {
  constructor() {
    this.name = '';
    this.size = '';
    this.color = '';
    this.class = '';
    this.loading = false;
  }
  get classes() {
    const icon = this.name ? `i-matcha-${this.name}` : '';
    const size = this.size ? `i-size-${this.size}` : '';
    const color = `color-${this.color}`;
    const loading = this.loading == true ? 'loading' : '';
    const activeClasses = `${icon} ${color} ${size} ${loading} ${this.class}`;
    return activeClasses;
  }
  static {
    this.ɵfac = function MatchaIconComponent_Factory(t) {
      return new (t || MatchaIconComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: MatchaIconComponent,
      selectors: [["matcha-icon"]],
      inputs: {
        name: "name",
        size: "size",
        color: "color",
        class: "class",
        loading: "loading"
      },
      ngContentSelectors: _c0,
      decls: 2,
      vars: 2,
      template: function MatchaIconComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "span");
          i0.ɵɵprojection(1);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.classes);
        }
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaIconComponent, [{
    type: Component,
    args: [{
      selector: 'matcha-icon',
      template: "<span [class]=\"classes\">\n    <ng-content></ng-content>\n</span>\n"
    }]
  }], null, {
    name: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    color: [{
      type: Input
    }],
    class: [{
      type: Input
    }],
    loading: [{
      type: Input
    }]
  });
})();
class MatchaCardFooter {}
class MatchaGridComponent {
  getStringSplited(inputValue) {
    const stringWithoutSpaces = inputValue.replace(/\s+/g, '');
    return stringWithoutSpaces.split(',');
  }
  getGridClasses(breakpoint, col) {
    const breakpoints = this.getStringSplited(breakpoint);
    const columns = this.getStringSplited(col);
    let breakpointClasses = '';
    for (let i = 0; i < breakpoints.length; i++) {
      if (breakpoints[i] === '' || breakpoints[i] === 'xs') {
        breakpointClasses += `grid-${columns[i]} `;
      }
      if (breakpoints[i] === 'sm' && columns[i] !== undefined) {
        breakpointClasses += `grid-sm-${columns[i]} `;
      }
      if (breakpoints[i] === 'md' && columns[i] !== undefined) {
        breakpointClasses += `grid-md-${columns[i]} `;
      }
      if (breakpoints[i] === 'lg' && columns[i] !== undefined) {
        breakpointClasses += `grid-lg-${columns[i]} `;
      }
      if (breakpoints[i] === 'xl' && columns[i] !== undefined) {
        breakpointClasses += `grid-xl-${columns[i]} `;
      }
    }
    return breakpointClasses;
  }
  setColspanClasses(span, breakpoint) {
    if (span) {
      const spanClasses = this.el.nativeElement.className.split(' ').filter(c => c.startsWith('colspan-'));
      spanClasses.forEach(c => this.renderer.removeClass(this.el.nativeElement, c));
      this.renderer.addClass(this.el.nativeElement, `colspan${breakpoint ? '-' + breakpoint : '-sm'}-${span}`);
    }
  }
  setRowspanClasses(span, breakpoint) {
    if (span) {
      const spanClasses = this.el.nativeElement.className.split(' ').filter(c => c.startsWith('rowspan-'));
      spanClasses.forEach(c => this.renderer.removeClass(this.el.nativeElement, c));
      this.renderer.addClass(this.el.nativeElement, `rowspan${breakpoint ? '-' + breakpoint : '-sm'}-${span}`);
    }
  }
  // public setRowspanClasses(rowspan: string, breakpoint: string): void {
  //     const elementsWithRowspanClasses = this.el.nativeElement.className.split(' ').filter((c: string) => c.startsWith('rowspan-'));
  //     elementsWithRowspanClasses.forEach((c: string) => this.renderer.removeClass(this.el.nativeElement, c));
  //     //função que executa a adição de classe para cada breakpoint
  //     const breakpoints = this.getStringSplited(breakpoint);
  //     const prefix: string = ' rowspan';
  //     const rows = this.getStringSplited(rowspan);
  //     let rowspanClasses = '';
  //     for (let i = 0; i < rows.length; i++) {
  //         rowspanClasses = prefix + rows[i];
  //     }
  //     this.renderer.addClass(this.el.nativeElement, `${rowspanClasses}`);
  // }
  get classes() {
    this.class = 'teste';
    const breakpoints = this.getGridClasses(this.breakpoint, this.col);
    const activeClasses = `grid-1 ${breakpoints} gap-${this.gap} ${this.loading ? 'loading' : ''}`;
    return activeClasses;
  }
  constructor(el, renderer) {
    this.el = el;
    this.renderer = renderer;
    this.breakpoint = '';
    this.col = '';
    this.gap = '';
    this.class = '';
    this.span = '';
    this.rowspan = '';
    this.colspan = '';
    this.direction = '';
    this.loading = false;
  }
  ngOnInit() {
    this.setColspanClasses(this.colspan, this.breakpoint);
    this.setRowspanClasses(this.rowspan, this.breakpoint);
  }
  ngOnChanges() {
    this.setColspanClasses(this.colspan, this.breakpoint);
    this.setRowspanClasses(this.rowspan, this.breakpoint);
  }
  static {
    this.ɵfac = function MatchaGridComponent_Factory(t) {
      return new (t || MatchaGridComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: MatchaGridComponent,
      selectors: [["matcha-grid"]],
      inputs: {
        breakpoint: "breakpoint",
        col: "col",
        gap: "gap",
        class: "class",
        span: "span",
        rowspan: "rowspan",
        colspan: "colspan",
        direction: "direction",
        loading: "loading"
      },
      features: [i0.ɵɵNgOnChangesFeature],
      ngContentSelectors: _c0,
      decls: 2,
      vars: 2,
      template: function MatchaGridComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "div");
          i0.ɵɵprojection(1);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.classes);
        }
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaGridComponent, [{
    type: Component,
    args: [{
      selector: 'matcha-grid',
      template: "<div [class]=\"classes\">\n    <ng-content></ng-content>\n</div>\n"
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }];
  }, {
    breakpoint: [{
      type: Input
    }],
    col: [{
      type: Input
    }],
    gap: [{
      type: Input
    }],
    class: [{
      type: Input
    }],
    span: [{
      type: Input
    }],
    rowspan: [{
      type: Input
    }],
    colspan: [{
      type: Input
    }],
    direction: [{
      type: Input
    }],
    loading: [{
      type: Input
    }]
  });
})();
class MatchaCardModule {
  static {
    this.ɵfac = function MatchaCardModule_Factory(t) {
      return new (t || MatchaCardModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: MatchaCardModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaCardModule, [{
    type: NgModule,
    args: [{
      declarations: [MatchaCardComponent],
      imports: [CommonModule],
      exports: [MatchaCardComponent]
    }]
  }], null, null);
})();
class MatchaHeadersModule {
  static {
    this.ɵfac = function MatchaHeadersModule_Factory(t) {
      return new (t || MatchaHeadersModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: MatchaHeadersModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaHeadersModule, [{
    type: NgModule,
    args: [{
      declarations: [MatchaTitleComponent, MatchaHeadlineComponent, MatchaSubheadComponent],
      imports: [CommonModule],
      exports: [MatchaTitleComponent, MatchaHeadlineComponent, MatchaSubheadComponent]
    }]
  }], null, null);
})();
class MatchaAutocompleteDirective {
  constructor(_elementRef, _renderer) {
    this._elementRef = _elementRef;
    this._renderer = _renderer;
    this._renderer.addClass(this._elementRef.nativeElement, 'matcha-autocomplete');
  }
  static {
    this.ɵfac = function MatchaAutocompleteDirective_Factory(t) {
      return new (t || MatchaAutocompleteDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: MatchaAutocompleteDirective,
      selectors: [["", "matcha-autocomplete", ""]]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaAutocompleteDirective, [{
    type: Directive,
    args: [{
      selector: '[matcha-autocomplete]'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }];
  }, null);
})();
class MatchaAutocompleteOverviewDirective {
  constructor(_elementRef, _renderer) {
    this._elementRef = _elementRef;
    this._renderer = _renderer;
    //this._elementRef.nativeElement.style.backgroundColor = 'grey';
    this._renderer.addClass(this._elementRef.nativeElement, 'matcha-autocomplete-overview');
  }
  static {
    this.ɵfac = function MatchaAutocompleteOverviewDirective_Factory(t) {
      return new (t || MatchaAutocompleteOverviewDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: MatchaAutocompleteOverviewDirective,
      selectors: [["", "matcha-autocomplete-overview", ""]]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaAutocompleteOverviewDirective, [{
    type: Directive,
    args: [{
      selector: '[matcha-autocomplete-overview]'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }];
  }, null);
})();
class MatchaAutocompleteModule {
  static {
    this.ɵfac = function MatchaAutocompleteModule_Factory(t) {
      return new (t || MatchaAutocompleteModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: MatchaAutocompleteModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaAutocompleteModule, [{
    type: NgModule,
    args: [{
      declarations: [MatchaAutocompleteDirective, MatchaAutocompleteOverviewDirective],
      imports: [],
      exports: [MatchaAutocompleteDirective, MatchaAutocompleteOverviewDirective]
    }]
  }], null, null);
})();
class MatchaBadgeDirective {
  constructor(_elementRef, _renderer) {
    this._elementRef = _elementRef;
    this._renderer = _renderer;
    //this._elementRef.nativeElement.style.backgroundColor = 'grey';
    this._renderer.addClass(this._elementRef.nativeElement, 'matcha-badge');
  }
  static {
    this.ɵfac = function MatchaBadgeDirective_Factory(t) {
      return new (t || MatchaBadgeDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: MatchaBadgeDirective,
      selectors: [["", "matcha-badge", ""]]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaBadgeDirective, [{
    type: Directive,
    args: [{
      selector: '[matcha-badge]'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }];
  }, null);
})();
class MatchaBadgeModule {
  static {
    this.ɵfac = function MatchaBadgeModule_Factory(t) {
      return new (t || MatchaBadgeModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: MatchaBadgeModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaBadgeModule, [{
    type: NgModule,
    args: [{
      declarations: [MatchaBadgeDirective],
      imports: [],
      exports: [MatchaBadgeDirective]
    }]
  }], null, null);
})();
class MatchaTabsDirective {
  constructor(_elementRef, _renderer) {
    this._elementRef = _elementRef;
    this._renderer = _renderer;
    //this._elementRef.nativeElement.style.backgroundColor = 'grey';
    this._renderer.addClass(this._elementRef.nativeElement, 'matcha-tabs');
  }
  static {
    this.ɵfac = function MatchaTabsDirective_Factory(t) {
      return new (t || MatchaTabsDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: MatchaTabsDirective,
      selectors: [["", "matchaTabs", ""]]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaTabsDirective, [{
    type: Directive,
    args: [{
      selector: '[matchaTabs]'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }];
  }, null);
})();
class MatchaTabsModule {
  static {
    this.ɵfac = function MatchaTabsModule_Factory(t) {
      return new (t || MatchaTabsModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: MatchaTabsModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaTabsModule, [{
    type: NgModule,
    args: [{
      declarations: [MatchaTabsDirective],
      imports: [CommonModule],
      exports: [MatchaTabsDirective]
    }]
  }], null, null);
})();
class MatchaBottomSheetDirective {
  constructor(_elementRef, _renderer) {
    this._elementRef = _elementRef;
    this._renderer = _renderer;
    //this._elementRef.nativeElement.style.backgroundColor = 'grey';
    this._renderer.addClass(this._elementRef.nativeElement, 'matcha-bottom-sheet');
  }
  static {
    this.ɵfac = function MatchaBottomSheetDirective_Factory(t) {
      return new (t || MatchaBottomSheetDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: MatchaBottomSheetDirective,
      selectors: [["", "matcha-bottom-sheet", ""]]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaBottomSheetDirective, [{
    type: Directive,
    args: [{
      selector: '[matcha-bottom-sheet]'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }];
  }, null);
})();
class MatchaBottomSheetModule {
  static {
    this.ɵfac = function MatchaBottomSheetModule_Factory(t) {
      return new (t || MatchaBottomSheetModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: MatchaBottomSheetModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaBottomSheetModule, [{
    type: NgModule,
    args: [{
      declarations: [MatchaBottomSheetDirective],
      imports: [],
      exports: [MatchaBottomSheetDirective]
    }]
  }], null, null);
})();
class MatchaButtonToggleDirective {
  constructor(_elementRef, _renderer) {
    this._elementRef = _elementRef;
    this._renderer = _renderer;
    this._renderer.addClass(this._elementRef.nativeElement, 'matcha-button-toggle');
  }
  static {
    this.ɵfac = function MatchaButtonToggleDirective_Factory(t) {
      return new (t || MatchaButtonToggleDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: MatchaButtonToggleDirective,
      selectors: [["", "matcha-button-toggle", ""]]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaButtonToggleDirective, [{
    type: Directive,
    args: [{
      selector: '[matcha-button-toggle]'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }];
  }, null);
})();
class MatchaButtonToggleModule {
  static {
    this.ɵfac = function MatchaButtonToggleModule_Factory(t) {
      return new (t || MatchaButtonToggleModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: MatchaButtonToggleModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaButtonToggleModule, [{
    type: NgModule,
    args: [{
      declarations: [MatchaButtonToggleDirective],
      imports: [],
      exports: [MatchaButtonToggleDirective]
    }]
  }], null, null);
})();
class MatchaButtonDirective {
  constructor(_elementRef, _renderer) {
    this._elementRef = _elementRef;
    this._renderer = _renderer;
    this.pill = false;
    this.size = null;
  }
  ngOnInit() {
    this._setConfig();
  }
  ngOnChanges() {
    this._setConfig();
    console.log(this.size);
  }
  _setConfig() {
    // Remove todas as classes relacionadas ao tamanho
    ['xs', 'sm', 'md', 'lg', 'xl'].forEach(size => {
      this._renderer.removeClass(this._elementRef.nativeElement, `matcha-button-${size}`);
    });
    // Adicione a classe correspondente ao novo tamanho (se houver)
    if (this.size) {
      this._renderer.addClass(this._elementRef.nativeElement, `matcha-button-${this.size}`);
    }
    if (this.pill) {
      this._renderer.addClass(this._elementRef.nativeElement, `matcha-button-pill`);
    } else {
      this._renderer.removeClass(this._elementRef.nativeElement, `matcha-button-pill`);
    }
  }
  static {
    this.ɵfac = function MatchaButtonDirective_Factory(t) {
      return new (t || MatchaButtonDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: MatchaButtonDirective,
      selectors: [["", "matcha-button", ""]],
      inputs: {
        pill: "pill",
        size: "size"
      },
      features: [i0.ɵɵNgOnChangesFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaButtonDirective, [{
    type: Directive,
    args: [{
      selector: '[matcha-button]',
      standalone: false
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }];
  }, {
    pill: [{
      type: Input,
      args: ['pill']
    }],
    size: [{
      type: Input,
      args: ['size']
    }]
  });
})();
class MatchaButtonModule {
  static {
    this.ɵfac = function MatchaButtonModule_Factory(t) {
      return new (t || MatchaButtonModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: MatchaButtonModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaButtonModule, [{
    type: NgModule,
    args: [{
      declarations: [MatchaButtonDirective],
      imports: [],
      exports: [MatchaButtonDirective]
    }]
  }], null, null);
})();
class MatchaCheckboxDirective {
  constructor(_elementRef, _renderer) {
    this._elementRef = _elementRef;
    this._renderer = _renderer;
    //this._elementRef.nativeElement.style.backgroundColor = 'grey';
    this._renderer.addClass(this._elementRef.nativeElement, 'matcha-checkbox');
  }
  static {
    this.ɵfac = function MatchaCheckboxDirective_Factory(t) {
      return new (t || MatchaCheckboxDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: MatchaCheckboxDirective,
      selectors: [["", "matcha-checkbox", ""]]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaCheckboxDirective, [{
    type: Directive,
    args: [{
      selector: '[matcha-checkbox]'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }];
  }, null);
})();
class MatchaCheckboxModule {
  static {
    this.ɵfac = function MatchaCheckboxModule_Factory(t) {
      return new (t || MatchaCheckboxModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: MatchaCheckboxModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaCheckboxModule, [{
    type: NgModule,
    args: [{
      declarations: [MatchaCheckboxDirective],
      imports: [],
      exports: [MatchaCheckboxDirective]
    }]
  }], null, null);
})();
class MatchaChipsDirective {
  constructor(_elementRef, _renderer) {
    this._elementRef = _elementRef;
    this._renderer = _renderer;
    //this._elementRef.nativeElement.style.backgroundColor = 'grey';
    this._renderer.addClass(this._elementRef.nativeElement, 'matcha-chips');
  }
  static {
    this.ɵfac = function MatchaChipsDirective_Factory(t) {
      return new (t || MatchaChipsDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: MatchaChipsDirective,
      selectors: [["", "matchaChips", ""]]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaChipsDirective, [{
    type: Directive,
    args: [{
      selector: '[matchaChips]'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }];
  }, null);
})();
class MatchaChipsModule {
  static {
    this.ɵfac = function MatchaChipsModule_Factory(t) {
      return new (t || MatchaChipsModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: MatchaChipsModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaChipsModule, [{
    type: NgModule,
    args: [{
      declarations: [MatchaChipsDirective],
      imports: [],
      exports: [MatchaChipsDirective]
    }]
  }], null, null);
})();
class MatchaDatepickerDirective {
  constructor(_elementRef, _renderer) {
    this._elementRef = _elementRef;
    this._renderer = _renderer;
    //this._elementRef.nativeElement.style.backgroundColor = 'grey';
    this._renderer.addClass(this._elementRef.nativeElement, 'matcha-datepicker');
  }
  static {
    this.ɵfac = function MatchaDatepickerDirective_Factory(t) {
      return new (t || MatchaDatepickerDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: MatchaDatepickerDirective,
      selectors: [["", "matcha-datepicker", ""]]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaDatepickerDirective, [{
    type: Directive,
    args: [{
      selector: '[matcha-datepicker]'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }];
  }, null);
})();
class MatchaDatepickerModule {
  static {
    this.ɵfac = function MatchaDatepickerModule_Factory(t) {
      return new (t || MatchaDatepickerModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: MatchaDatepickerModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaDatepickerModule, [{
    type: NgModule,
    args: [{
      declarations: [MatchaDatepickerDirective],
      imports: [],
      exports: [MatchaDatepickerDirective]
    }]
  }], null, null);
})();
class MatchaDialogDirective {
  constructor(_elementRef, _renderer) {
    this._elementRef = _elementRef;
    this._renderer = _renderer;
    //this._elementRef.nativeElement.style.backgroundColor = 'grey';
    this._renderer.addClass(this._elementRef.nativeElement, 'matcha-dialog');
  }
  static {
    this.ɵfac = function MatchaDialogDirective_Factory(t) {
      return new (t || MatchaDialogDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: MatchaDialogDirective,
      selectors: [["", "matchaDialog", ""]]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaDialogDirective, [{
    type: Directive,
    args: [{
      selector: '[matchaDialog]'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }];
  }, null);
})();
class MatchaDialogModule {
  static {
    this.ɵfac = function MatchaDialogModule_Factory(t) {
      return new (t || MatchaDialogModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: MatchaDialogModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaDialogModule, [{
    type: NgModule,
    args: [{
      declarations: [MatchaDialogDirective],
      imports: [CommonModule],
      exports: [MatchaDialogDirective]
    }]
  }], null, null);
})();
class MatchaDividerDirective {
  constructor(_elementRef, _renderer) {
    this._elementRef = _elementRef;
    this._renderer = _renderer;
    //this._elementRef.nativeElement.style.backgroundColor = 'grey';
    this._renderer.addClass(this._elementRef.nativeElement, 'matcha-dialog');
  }
  static {
    this.ɵfac = function MatchaDividerDirective_Factory(t) {
      return new (t || MatchaDividerDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: MatchaDividerDirective,
      selectors: [["", "matchaDivider", ""]]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaDividerDirective, [{
    type: Directive,
    args: [{
      selector: '[matchaDivider]'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }];
  }, null);
})();
class MatchaDividerModule {
  static {
    this.ɵfac = function MatchaDividerModule_Factory(t) {
      return new (t || MatchaDividerModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: MatchaDividerModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaDividerModule, [{
    type: NgModule,
    args: [{
      declarations: [MatchaDividerDirective],
      imports: [CommonModule],
      exports: [MatchaDividerDirective]
    }]
  }], null, null);
})();
class MatchaElevationDirective {
  constructor(_elementRef, _renderer) {
    this._elementRef = _elementRef;
    this._renderer = _renderer;
    this.elevation = 0; // Valor padrão para elevação é 0
  }
  ngOnChanges() {
    // Remove qualquer classe de elevação existente
    for (let i = 0; i <= 24; i++) {
      this._renderer.removeClass(this._elementRef.nativeElement, `elevation-z-${i}`);
    }
    // Adiciona a classe de elevação correspondente
    if (this.elevation >= 1 && this.elevation <= 24) {
      this._renderer.addClass(this._elementRef.nativeElement, `elevation-z-${this.elevation}`);
    } else {
      this._renderer.addClass(this._elementRef.nativeElement, `elevation-z-0`);
    }
  }
  static {
    this.ɵfac = function MatchaElevationDirective_Factory(t) {
      return new (t || MatchaElevationDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: MatchaElevationDirective,
      selectors: [["", "elevation", ""]],
      inputs: {
        elevation: "elevation"
      },
      features: [i0.ɵɵNgOnChangesFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaElevationDirective, [{
    type: Directive,
    args: [{
      selector: '[elevation]'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }];
  }, {
    elevation: [{
      type: Input,
      args: ['elevation']
    }]
  });
})();
class MatchaElevationModule {
  static {
    this.ɵfac = function MatchaElevationModule_Factory(t) {
      return new (t || MatchaElevationModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: MatchaElevationModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaElevationModule, [{
    type: NgModule,
    args: [{
      declarations: [MatchaElevationDirective],
      imports: [CommonModule],
      exports: [MatchaElevationDirective]
    }]
  }], null, null);
})();
class MatchaExpansionDirective {
  constructor(_elementRef, _renderer) {
    this._elementRef = _elementRef;
    this._renderer = _renderer;
    //this._elementRef.nativeElement.style.backgroundColor = 'grey';
    this._renderer.addClass(this._elementRef.nativeElement, 'matcha-expansion-panel');
  }
  static {
    this.ɵfac = function MatchaExpansionDirective_Factory(t) {
      return new (t || MatchaExpansionDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: MatchaExpansionDirective,
      selectors: [["", "matchaExpansion", ""]]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaExpansionDirective, [{
    type: Directive,
    args: [{
      selector: '[matchaExpansion]'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }];
  }, null);
})();
class MatchaExpansionModule {
  static {
    this.ɵfac = function MatchaExpansionModule_Factory(t) {
      return new (t || MatchaExpansionModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: MatchaExpansionModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaExpansionModule, [{
    type: NgModule,
    args: [{
      declarations: [MatchaExpansionDirective],
      imports: [CommonModule],
      exports: [MatchaExpansionDirective]
    }]
  }], null, null);
})();
class MatchaFormFieldDirective {
  constructor(_elementRef, _renderer) {
    this._elementRef = _elementRef;
    this._renderer = _renderer;
    //this._elementRef.nativeElement.style.backgroundColor = 'grey';
    this._renderer.addClass(this._elementRef.nativeElement, 'matcha-form-field');
  }
  static {
    this.ɵfac = function MatchaFormFieldDirective_Factory(t) {
      return new (t || MatchaFormFieldDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: MatchaFormFieldDirective,
      selectors: [["", "matchaFormField", ""]]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaFormFieldDirective, [{
    type: Directive,
    args: [{
      selector: '[matchaFormField]'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }];
  }, null);
})();
class MatchaFormsModule {
  static {
    this.ɵfac = function MatchaFormsModule_Factory(t) {
      return new (t || MatchaFormsModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: MatchaFormsModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaFormsModule, [{
    type: NgModule,
    args: [{
      declarations: [MatchaFormFieldDirective],
      imports: [CommonModule],
      exports: [MatchaFormFieldDirective]
    }]
  }], null, null);
})();
class MatchaIconModule {
  static {
    this.ɵfac = function MatchaIconModule_Factory(t) {
      return new (t || MatchaIconModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: MatchaIconModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaIconModule, [{
    type: NgModule,
    args: [{
      declarations: [MatchaIconComponent],
      imports: [CommonModule],
      exports: [MatchaIconComponent]
    }]
  }], null, null);
})();
class MatchaInputDirective {
  constructor(_elementRef, _renderer) {
    this._elementRef = _elementRef;
    this._renderer = _renderer;
    //this._elementRef.nativeElement.style.backgroundColor = 'grey';
    this._renderer.addClass(this._elementRef.nativeElement, 'matcha-input');
  }
  static {
    this.ɵfac = function MatchaInputDirective_Factory(t) {
      return new (t || MatchaInputDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: MatchaInputDirective,
      selectors: [["", "matcha-input", ""]]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaInputDirective, [{
    type: Directive,
    args: [{
      selector: '[matcha-input]'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }];
  }, null);
})();
class MatchaInputModule {
  static {
    this.ɵfac = function MatchaInputModule_Factory(t) {
      return new (t || MatchaInputModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: MatchaInputModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaInputModule, [{
    type: NgModule,
    args: [{
      declarations: [MatchaInputDirective],
      imports: [],
      exports: [MatchaInputDirective]
    }]
  }], null, null);
})();
class MatchaListDirective {
  constructor(_elementRef, _renderer) {
    this._elementRef = _elementRef;
    this._renderer = _renderer;
    //this._elementRef.nativeElement.style.backgroundColor = 'grey';
    this._renderer.addClass(this._elementRef.nativeElement, 'matcha-list');
  }
  static {
    this.ɵfac = function MatchaListDirective_Factory(t) {
      return new (t || MatchaListDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: MatchaListDirective,
      selectors: [["", "matchaList", ""]]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaListDirective, [{
    type: Directive,
    args: [{
      selector: '[matchaList]'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }];
  }, null);
})();
class MatchaListModule {
  static {
    this.ɵfac = function MatchaListModule_Factory(t) {
      return new (t || MatchaListModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: MatchaListModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaListModule, [{
    type: NgModule,
    args: [{
      declarations: [MatchaListDirective],
      imports: [CommonModule],
      exports: [MatchaListDirective]
    }]
  }], null, null);
})();
class MatchaMenuDirective {
  constructor(_elementRef, _renderer) {
    this._elementRef = _elementRef;
    this._renderer = _renderer;
    //this._elementRef.nativeElement.style.backgroundColor = 'grey';
    this._renderer.addClass(this._elementRef.nativeElement, 'matcha-menu');
  }
  static {
    this.ɵfac = function MatchaMenuDirective_Factory(t) {
      return new (t || MatchaMenuDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: MatchaMenuDirective,
      selectors: [["", "matchaMenu", ""]]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaMenuDirective, [{
    type: Directive,
    args: [{
      selector: '[matchaMenu]'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }];
  }, null);
})();
class MatchaMenuModule {
  static {
    this.ɵfac = function MatchaMenuModule_Factory(t) {
      return new (t || MatchaMenuModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: MatchaMenuModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaMenuModule, [{
    type: NgModule,
    args: [{
      declarations: [MatchaMenuDirective],
      imports: [CommonModule],
      exports: [MatchaMenuDirective]
    }]
  }], null, null);
})();
class MatchaSidenavDirective {
  constructor(_elementRef, _renderer) {
    this._elementRef = _elementRef;
    this._renderer = _renderer;
    //this._elementRef.nativeElement.style.backgroundColor = 'grey';
    this._renderer.addClass(this._elementRef.nativeElement, 'matcha-sidenav');
  }
  static {
    this.ɵfac = function MatchaSidenavDirective_Factory(t) {
      return new (t || MatchaSidenavDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: MatchaSidenavDirective,
      selectors: [["", "matcha-sidenav", ""]]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaSidenavDirective, [{
    type: Directive,
    args: [{
      selector: '[matcha-sidenav]'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }];
  }, null);
})();
class MatchaSidenavModule {
  static {
    this.ɵfac = function MatchaSidenavModule_Factory(t) {
      return new (t || MatchaSidenavModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: MatchaSidenavModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaSidenavModule, [{
    type: NgModule,
    args: [{
      declarations: [MatchaSidenavDirective],
      imports: [CommonModule],
      exports: [MatchaSidenavDirective]
    }]
  }], null, null);
})();
class MatchaPaginatorDirective {
  constructor(_elementRef, _renderer) {
    this._elementRef = _elementRef;
    this._renderer = _renderer;
    //this._elementRef.nativeElement.style.backgroundColor = 'grey';
    this._renderer.addClass(this._elementRef.nativeElement, 'matcha-paginator');
  }
  static {
    this.ɵfac = function MatchaPaginatorDirective_Factory(t) {
      return new (t || MatchaPaginatorDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: MatchaPaginatorDirective,
      selectors: [["", "matchaPaginator", ""]]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaPaginatorDirective, [{
    type: Directive,
    args: [{
      selector: '[matchaPaginator]'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }];
  }, null);
})();
class MatchaPaginatorModule {
  static {
    this.ɵfac = function MatchaPaginatorModule_Factory(t) {
      return new (t || MatchaPaginatorModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: MatchaPaginatorModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaPaginatorModule, [{
    type: NgModule,
    args: [{
      declarations: [MatchaPaginatorDirective],
      imports: [CommonModule],
      exports: [MatchaPaginatorDirective]
    }]
  }], null, null);
})();
class MatchaProgressBarDirective {
  constructor(_elementRef, _renderer) {
    this._elementRef = _elementRef;
    this._renderer = _renderer;
    //this._elementRef.nativeElement.style.backgroundColor = 'grey';
    this._renderer.addClass(this._elementRef.nativeElement, 'matcha-progress-bar');
  }
  static {
    this.ɵfac = function MatchaProgressBarDirective_Factory(t) {
      return new (t || MatchaProgressBarDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: MatchaProgressBarDirective,
      selectors: [["", "matcha-progress-bar", ""]]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaProgressBarDirective, [{
    type: Directive,
    args: [{
      selector: '[matcha-progress-bar]'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }];
  }, null);
})();
class MatchaProgressBarModule {
  static {
    this.ɵfac = function MatchaProgressBarModule_Factory(t) {
      return new (t || MatchaProgressBarModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: MatchaProgressBarModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaProgressBarModule, [{
    type: NgModule,
    args: [{
      declarations: [MatchaProgressBarDirective],
      imports: [CommonModule],
      exports: [MatchaProgressBarDirective]
    }]
  }], null, null);
})();
class MatchaProgressSpinnerDirective {
  constructor(_elementRef, _renderer) {
    this._elementRef = _elementRef;
    this._renderer = _renderer;
    //this._elementRef.nativeElement.style.backgroundColor = 'grey';
    this._renderer.addClass(this._elementRef.nativeElement, 'matcha-progress-spinner');
  }
  static {
    this.ɵfac = function MatchaProgressSpinnerDirective_Factory(t) {
      return new (t || MatchaProgressSpinnerDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: MatchaProgressSpinnerDirective,
      selectors: [["", "matcha-progress-spinner", ""]]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaProgressSpinnerDirective, [{
    type: Directive,
    args: [{
      selector: '[matcha-progress-spinner]'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }];
  }, null);
})();
class MatchaProgressSpinnerModule {
  static {
    this.ɵfac = function MatchaProgressSpinnerModule_Factory(t) {
      return new (t || MatchaProgressSpinnerModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: MatchaProgressSpinnerModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaProgressSpinnerModule, [{
    type: NgModule,
    args: [{
      declarations: [MatchaProgressSpinnerDirective],
      imports: [CommonModule],
      exports: [MatchaProgressSpinnerDirective]
    }]
  }], null, null);
})();
class MatchaRadioButtonDirective {
  constructor(_elementRef, _renderer) {
    this._elementRef = _elementRef;
    this._renderer = _renderer;
    //this._elementRef.nativeElement.style.backgroundColor = 'grey';
    this._renderer.addClass(this._elementRef.nativeElement, 'matcha-radio-button');
  }
  static {
    this.ɵfac = function MatchaRadioButtonDirective_Factory(t) {
      return new (t || MatchaRadioButtonDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: MatchaRadioButtonDirective,
      selectors: [["", "matchaRadioButton", ""]]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaRadioButtonDirective, [{
    type: Directive,
    args: [{
      selector: '[matchaRadioButton]'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }];
  }, null);
})();
class MatchaRadioButtonModule {
  static {
    this.ɵfac = function MatchaRadioButtonModule_Factory(t) {
      return new (t || MatchaRadioButtonModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: MatchaRadioButtonModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaRadioButtonModule, [{
    type: NgModule,
    args: [{
      declarations: [MatchaRadioButtonDirective],
      imports: [CommonModule],
      exports: [MatchaRadioButtonDirective]
    }]
  }], null, null);
})();
class MatchaSelectDirective {
  constructor(_elementRef, _renderer) {
    this._elementRef = _elementRef;
    this._renderer = _renderer;
    //this._elementRef.nativeElement.style.backgroundColor = 'grey';
    this._renderer.addClass(this._elementRef.nativeElement, 'matcha-select');
  }
  static {
    this.ɵfac = function MatchaSelectDirective_Factory(t) {
      return new (t || MatchaSelectDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: MatchaSelectDirective,
      selectors: [["", "matcha-select", ""]]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaSelectDirective, [{
    type: Directive,
    args: [{
      selector: '[matcha-select]'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }];
  }, null);
})();
class MatchaSelectModule {
  static {
    this.ɵfac = function MatchaSelectModule_Factory(t) {
      return new (t || MatchaSelectModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: MatchaSelectModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaSelectModule, [{
    type: NgModule,
    args: [{
      declarations: [MatchaSelectDirective],
      imports: [CommonModule],
      exports: [MatchaSelectDirective]
    }]
  }], null, null);
})();
class MatchaSlideToggleDirective {
  constructor(_elementRef, _renderer) {
    this._elementRef = _elementRef;
    this._renderer = _renderer;
    //this._elementRef.nativeElement.style.backgroundColor = 'grey';
    this._renderer.addClass(this._elementRef.nativeElement, 'matcha-slide-toggle');
  }
  static {
    this.ɵfac = function MatchaSlideToggleDirective_Factory(t) {
      return new (t || MatchaSlideToggleDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: MatchaSlideToggleDirective,
      selectors: [["", "slide-toggle", ""]]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaSlideToggleDirective, [{
    type: Directive,
    args: [{
      selector: '[slide-toggle]'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }];
  }, null);
})();
class MatchaSlideToggleModule {
  static {
    this.ɵfac = function MatchaSlideToggleModule_Factory(t) {
      return new (t || MatchaSlideToggleModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: MatchaSlideToggleModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaSlideToggleModule, [{
    type: NgModule,
    args: [{
      declarations: [MatchaSlideToggleDirective],
      imports: [CommonModule],
      exports: [MatchaSlideToggleDirective]
    }]
  }], null, null);
})();
class MatchaSliderDirective {
  constructor(_elementRef, _renderer) {
    this._elementRef = _elementRef;
    this._renderer = _renderer;
    //this._elementRef.nativeElement.style.backgroundColor = 'grey';
    this._renderer.addClass(this._elementRef.nativeElement, 'matcha-slider');
  }
  static {
    this.ɵfac = function MatchaSliderDirective_Factory(t) {
      return new (t || MatchaSliderDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: MatchaSliderDirective,
      selectors: [["", "matchaSlider", ""]]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaSliderDirective, [{
    type: Directive,
    args: [{
      selector: '[matchaSlider]'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }];
  }, null);
})();
class MatchaSliderModule {
  static {
    this.ɵfac = function MatchaSliderModule_Factory(t) {
      return new (t || MatchaSliderModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: MatchaSliderModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaSliderModule, [{
    type: NgModule,
    args: [{
      declarations: [MatchaSliderDirective],
      imports: [CommonModule],
      exports: [MatchaSliderDirective]
    }]
  }], null, null);
})();
class MatchaSnackBarDirective {
  constructor(_elementRef, _renderer) {
    this._elementRef = _elementRef;
    this._renderer = _renderer;
    //this._elementRef.nativeElement.style.backgroundColor = 'grey';
    this._renderer.addClass(this._elementRef.nativeElement, 'matcha-snackbar');
  }
  static {
    this.ɵfac = function MatchaSnackBarDirective_Factory(t) {
      return new (t || MatchaSnackBarDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: MatchaSnackBarDirective,
      selectors: [["", "matchaSnackBar", ""]]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaSnackBarDirective, [{
    type: Directive,
    args: [{
      selector: '[matchaSnackBar]'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }];
  }, null);
})();
class MatchaSnackBarModule {
  static {
    this.ɵfac = function MatchaSnackBarModule_Factory(t) {
      return new (t || MatchaSnackBarModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: MatchaSnackBarModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaSnackBarModule, [{
    type: NgModule,
    args: [{
      declarations: [MatchaSnackBarDirective],
      imports: [CommonModule],
      exports: [MatchaSnackBarDirective]
    }]
  }], null, null);
})();
class MatchaSortHeaderDirective {
  constructor(_elementRef, _renderer) {
    this._elementRef = _elementRef;
    this._renderer = _renderer;
    //this._elementRef.nativeElement.style.backgroundColor = 'grey';
    this._renderer.addClass(this._elementRef.nativeElement, 'matcha-sort-header');
  }
  static {
    this.ɵfac = function MatchaSortHeaderDirective_Factory(t) {
      return new (t || MatchaSortHeaderDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: MatchaSortHeaderDirective,
      selectors: [["", "matchaSortHeader", ""]]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaSortHeaderDirective, [{
    type: Directive,
    args: [{
      selector: '[matchaSortHeader]'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }];
  }, null);
})();
class MatchaSortHeaderModule {
  static {
    this.ɵfac = function MatchaSortHeaderModule_Factory(t) {
      return new (t || MatchaSortHeaderModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: MatchaSortHeaderModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaSortHeaderModule, [{
    type: NgModule,
    args: [{
      declarations: [MatchaSortHeaderDirective],
      imports: [CommonModule],
      exports: [MatchaSortHeaderDirective]
    }]
  }], null, null);
})();
class MatchaStepperDirective {
  constructor(_elementRef, _renderer) {
    this._elementRef = _elementRef;
    this._renderer = _renderer;
    //this._elementRef.nativeElement.style.backgroundColor = 'grey';
    this._renderer.addClass(this._elementRef.nativeElement, 'matcha-stepper');
  }
  static {
    this.ɵfac = function MatchaStepperDirective_Factory(t) {
      return new (t || MatchaStepperDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: MatchaStepperDirective,
      selectors: [["", "matchaStepper", ""]]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaStepperDirective, [{
    type: Directive,
    args: [{
      selector: '[matchaStepper]'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }];
  }, null);
})();
class MatchaStepperModule {
  static {
    this.ɵfac = function MatchaStepperModule_Factory(t) {
      return new (t || MatchaStepperModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: MatchaStepperModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaStepperModule, [{
    type: NgModule,
    args: [{
      declarations: [MatchaStepperDirective],
      imports: [CommonModule],
      exports: [MatchaStepperDirective]
    }]
  }], null, null);
})();
class MatchaTableDirective {
  constructor(_elementRef, _renderer) {
    this._elementRef = _elementRef;
    this._renderer = _renderer;
    //this._elementRef.nativeElement.style.backgroundColor = 'grey';
    this._renderer.addClass(this._elementRef.nativeElement, 'matcha-table');
  }
  static {
    this.ɵfac = function MatchaTableDirective_Factory(t) {
      return new (t || MatchaTableDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: MatchaTableDirective,
      selectors: [["", "matchaTable", ""]]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaTableDirective, [{
    type: Directive,
    args: [{
      selector: '[matchaTable]'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }];
  }, null);
})();
class MatchaTableModule {
  static {
    this.ɵfac = function MatchaTableModule_Factory(t) {
      return new (t || MatchaTableModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: MatchaTableModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaTableModule, [{
    type: NgModule,
    args: [{
      declarations: [MatchaTableDirective],
      imports: [CommonModule],
      exports: [MatchaTableDirective]
    }]
  }], null, null);
})();
class MatchaTooltipDirective {
  constructor(_elementRef, _renderer) {
    this._elementRef = _elementRef;
    this._renderer = _renderer;
    //this._elementRef.nativeElement.style.backgroundColor = 'grey';
    this._renderer.addClass(this._elementRef.nativeElement, 'matcha-tooltip');
  }
  static {
    this.ɵfac = function MatchaTooltipDirective_Factory(t) {
      return new (t || MatchaTooltipDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: MatchaTooltipDirective,
      selectors: [["", "matchaTooltip", ""]]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaTooltipDirective, [{
    type: Directive,
    args: [{
      selector: '[matchaTooltip]'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }];
  }, null);
})();
class MatchaTooltipModule {
  static {
    this.ɵfac = function MatchaTooltipModule_Factory(t) {
      return new (t || MatchaTooltipModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: MatchaTooltipModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaTooltipModule, [{
    type: NgModule,
    args: [{
      declarations: [MatchaTooltipDirective],
      imports: [CommonModule],
      exports: [MatchaTooltipDirective]
    }]
  }], null, null);
})();
class MatchaTreeDirective {
  constructor(_elementRef, _renderer) {
    this._elementRef = _elementRef;
    this._renderer = _renderer;
    //this._elementRef.nativeElement.style.backgroundColor = 'grey';
    this._renderer.addClass(this._elementRef.nativeElement, 'matcha-tree');
  }
  static {
    this.ɵfac = function MatchaTreeDirective_Factory(t) {
      return new (t || MatchaTreeDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: MatchaTreeDirective,
      selectors: [["", "matchaTree", ""]]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaTreeDirective, [{
    type: Directive,
    args: [{
      selector: '[matchaTree]'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }];
  }, null);
})();
class MatchaTreeModule {
  static {
    this.ɵfac = function MatchaTreeModule_Factory(t) {
      return new (t || MatchaTreeModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: MatchaTreeModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaTreeModule, [{
    type: NgModule,
    args: [{
      declarations: [MatchaTreeDirective],
      imports: [CommonModule],
      exports: [MatchaTreeDirective]
    }]
  }], null, null);
})();
class MatchaComponentsModule {
  static {
    this.ɵfac = function MatchaComponentsModule_Factory(t) {
      return new (t || MatchaComponentsModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: MatchaComponentsModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [MatchaCardModule, MatchaHeadersModule, MatchaCardModule, MatchaHeadersModule, MatchaAutocompleteModule, MatchaBadgeModule, MatchaTabsModule, MatchaBottomSheetModule, MatchaButtonToggleModule, MatchaButtonModule, MatchaCheckboxModule, MatchaChipsModule, MatchaDatepickerModule, MatchaDialogModule, MatchaDividerModule, MatchaElevationModule, MatchaExpansionModule, MatchaFormsModule, MatchaIconModule, MatchaInputModule, MatchaListModule, MatchaMenuModule, MatchaSidenavModule, MatchaPaginatorModule, MatchaProgressBarModule, MatchaProgressSpinnerModule, MatchaRadioButtonModule, MatchaSelectModule, MatchaSlideToggleModule, MatchaSliderModule, MatchaSnackBarModule, MatchaSortHeaderModule, MatchaStepperModule, MatchaTableModule, MatchaTabsModule, MatchaTooltipModule, MatchaTreeModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaComponentsModule, [{
    type: NgModule,
    args: [{
      declarations: [],
      imports: [MatchaCardModule, MatchaHeadersModule],
      exports: [MatchaCardModule, MatchaHeadersModule, MatchaAutocompleteModule, MatchaBadgeModule, MatchaTabsModule, MatchaBottomSheetModule, MatchaButtonToggleModule, MatchaButtonModule, MatchaCheckboxModule, MatchaChipsModule, MatchaDatepickerModule, MatchaDialogModule, MatchaDividerModule, MatchaElevationModule, MatchaExpansionModule, MatchaFormsModule, MatchaIconModule, MatchaInputModule, MatchaListModule, MatchaMenuModule, MatchaSidenavModule, MatchaPaginatorModule, MatchaProgressBarModule, MatchaProgressSpinnerModule, MatchaRadioButtonModule, MatchaSelectModule, MatchaSlideToggleModule, MatchaSliderModule, MatchaSnackBarModule, MatchaSortHeaderModule, MatchaStepperModule, MatchaTableModule, MatchaTabsModule, MatchaTooltipModule, MatchaTreeModule]
    }]
  }], null, null);
})();
class MatchaGridModule {
  static {
    this.ɵfac = function MatchaGridModule_Factory(t) {
      return new (t || MatchaGridModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: MatchaGridModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatchaGridModule, [{
    type: NgModule,
    args: [{
      declarations: [MatchaGridComponent],
      imports: [CommonModule],
      exports: [MatchaGridComponent]
    }]
  }], null, null);
})();

/*
 * Public API Surface of matcha-components
 */
// -----------------------------------------------------------
// COMPONENTS
// -----------------------------------------------------------
// // ATOMIC COMPONENTS
// export * from './lib/matcha-button/matcha-button.component';
// // BASE COMPONENT
// export * from './lib/matcha-title/matcha-title.component';
//
// -----------------------------------------------------------
// COMPONENTS
// -----------------------------------------------------------

/**
 * Generated bundle index. Do not edit.
 */

export { MatchaAutocompleteDirective, MatchaAutocompleteModule, MatchaAutocompleteOverviewDirective, MatchaBadgeDirective, MatchaBadgeModule, MatchaBottomSheetDirective, MatchaBottomSheetModule, MatchaButtonDirective, MatchaButtonModule, MatchaButtonToggleDirective, MatchaButtonToggleModule, MatchaCardComponent, MatchaCardFooter, MatchaCardModule, MatchaCheckboxDirective, MatchaCheckboxModule, MatchaChipsDirective, MatchaChipsModule, MatchaComponentsModule, MatchaDatepickerDirective, MatchaDatepickerModule, MatchaDialogDirective, MatchaDialogModule, MatchaDividerDirective, MatchaDividerModule, MatchaElevationDirective, MatchaElevationModule, MatchaExpansionDirective, MatchaExpansionModule, MatchaFormFieldDirective, MatchaFormsModule, MatchaGridComponent, MatchaGridModule, MatchaHeadersModule, MatchaHeadlineComponent, MatchaIconComponent, MatchaIconModule, MatchaInputDirective, MatchaInputModule, MatchaListDirective, MatchaListModule, MatchaMenuDirective, MatchaMenuModule, MatchaPaginatorDirective, MatchaPaginatorModule, MatchaProgressBarDirective, MatchaProgressBarModule, MatchaProgressSpinnerDirective, MatchaProgressSpinnerModule, MatchaRadioButtonDirective, MatchaRadioButtonModule, MatchaSelectDirective, MatchaSelectModule, MatchaSidenavDirective, MatchaSidenavModule, MatchaSlideToggleDirective, MatchaSlideToggleModule, MatchaSliderDirective, MatchaSliderModule, MatchaSnackBarDirective, MatchaSnackBarModule, MatchaSortHeaderDirective, MatchaSortHeaderModule, MatchaStepperDirective, MatchaStepperModule, MatchaSubheadComponent, MatchaTableDirective, MatchaTableModule, MatchaTabsDirective, MatchaTabsModule, MatchaTitleComponent, MatchaTooltipDirective, MatchaTooltipModule, MatchaTreeDirective, MatchaTreeModule };
